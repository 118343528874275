import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { GetStaticProps } from "next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql } from "@urql/core";
import { publicUrqlClient } from "lib/graphqlClient";
import { useRecoilState } from "recoil";
import { ChatWidgetState, ChatWidgetStateType } from "recoil/chat";

// mui
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useLoading } from "lib/loadingContext";

const formSchema = yup.object().shape({
  code: yup.string().required(),
});

type FormData = {
  code: string;
};

const QUERY = gql`
  query Tenant($code: String!) {
    tenant(code: $code) {
      auth0OrgId
    }
  }
`;
const Login = () => {
  const router = useRouter();
  const { setLoading } = useLoading();
  const [, setWidgetOpenedGUIDs] = useRecoilState<ChatWidgetStateType>(ChatWidgetState);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(formSchema) });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLoading(isSubmitting), [isSubmitting]);

  // 初期状態でcodeにフォーカス
  useEffect(() => {
    setFocus("code");
  }, []);

  const returnTo = router.query.returnTo as string;

  const onSubmit = async (data: FormData) => {
    clearErrors();
    const res = await publicUrqlClient.query(QUERY, { code: data.code }).toPromise();
    if (res.data.tenant) {
      router.push({
        pathname: "api/auth/login",
        query: {
          organization: res.data.tenant.auth0OrgId,
          return_to: returnTo,
        },
      });
    } else {
      setError("code", { type: "custom", message: "not found" });
    }
  };

  // import "../styles/globals.css";がpages/_app.tsxにあるので白くするのにBoxでwrapしてる
  // color adminが消えれば不要
  return (
    <Box sx={{ height: "100vh", background: "white" }}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, mt: 16, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nitoel
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: 400 }}>
            <Controller
              name="code"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  error={!(errors.code === undefined)}
                  helperText={errors.code?.message}
                  margin="normal"
                  required
                  fullWidth
                  label="テナントコード"
                  autoFocus
                />
              )}
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      layout: "none",
    },
  };
};

export default Login;
